'use client'

import { RiArrowDownCircleFill } from '@remixicon/react'
import { Button } from 'antd'
import { RefObject, useEffect, useState } from 'react'

import { cn } from '@/utils/clsx'

interface ScrollToBottomProps {
  containerRef: RefObject<HTMLDivElement>
  changeIndicator?: any
  classname?: string
}

const ScrollToBottom: React.FC<ScrollToBottomProps> = ({
  containerRef,
  changeIndicator,
  classname,
}) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true)

  const scrollToBottom = () => {
    containerRef.current?.scrollTo({
      top: containerRef.current.scrollHeight,
      behavior: 'smooth',
    })
  }

  const handleScroll = () => {
    if (
      containerRef.current &&
      containerRef.current.scrollHeight - containerRef.current.scrollTop - 50 <=
        containerRef.current.clientHeight
    ) {
      setIsScrolledToBottom(true)
    } else {
      setIsScrolledToBottom(false)
    }
  }

  useEffect(() => {
    if (containerRef?.current) {
      handleScroll()
      containerRef.current?.addEventListener('scroll', handleScroll)
      return () => {
        containerRef.current?.removeEventListener('scroll', handleScroll)
      }
    }
  }, [containerRef?.current])

  useEffect(() => {
    if (containerRef?.current) {
      handleScroll()
    }
  }, [changeIndicator])

  if (isScrolledToBottom) {
    return null
  }

  return (
    <div
      className={cn(
        'to-transparent pointer-events-none flex h-20 w-full items-end justify-center bg-gradient-to-t from-background/70 dark:from-dark-background/70',
        classname
      )}
    >
      <Button
        onClick={scrollToBottom}
        icon={<RiArrowDownCircleFill className='size-6' />}
        className='pointer-events-auto m-2'
        type='text'
      />
    </div>
  )
}

export default ScrollToBottom
