'use client'

import {
  RiArrowRightSLine,
  RiChatPollLine,
  RiContractLine,
  RiSearchLine,
} from '@remixicon/react'
import { Button, Divider, Form, Grid, Input, Tooltip } from 'antd'
import Lottie from 'react-lottie'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import { generateUUID } from '@/utils'

import lottieChatLoadingScreenAnimation from '../../../public/lottieChatLoadingScreen.json'

import { GrantApplicationMode } from '@/types/grants'

const InitialStep: React.FC = () => {
  const { setCurrentStep, currentStep, steps, setSteps, setMode } =
    useGrantApplicationState()
  const { setSelectedConversation } = useDrawerState()
  const screens = Grid.useBreakpoint()

  const finish = async (values: { [x: string]: string }) => {
    setMode(GrantApplicationMode.DRAFTING)
    setCurrentStep(1)
    setSelectedConversation(generateUUID())
    setSteps({
      ...steps,
      0: values,
    })
  }

  if (screens.lg) {
    return (
      <div className='m-auto grid h-screen grid-cols-2 items-center justify-center gap-2'>
        <div className='flex flex-col gap-2'>
          <div className='flex items-center justify-center gap-2 rounded-lg px-4 py-2 transition-all'>
            <p className='text-center text-5xl xl:text-6xl'>SmartGrants.ai</p>
          </div>
          <div className='m-auto max-w-screen-lg pl-10'>
            <Lottie
              options={{ animationData: lottieChatLoadingScreenAnimation }}
              isClickToPauseDisabled
              style={{ cursor: 'default' }}
            />
          </div>
        </div>
        <div className='flex h-full flex-col gap-5 overflow-y-auto bg-surface/50 text-on-surface dark:bg-dark-surface/50 dark:text-dark-on-surface'>
          <div className='m-auto flex max-w-screen-sm flex-col justify-center gap-5 p-4'>
            <Form
              onFinish={finish}
              autoComplete='off'
              requiredMark='optional'
              layout='vertical'
              initialValues={steps[currentStep]}
              className='flex flex-col text-left'
            >
              <div className='flex size-full flex-col'>
                <div className='flex flex-col justify-center'>
                  <p className='mb-8 text-base'>
                    Enter the company URL to help us find the best grants. Add
                    more details below for more accurate grant search.
                  </p>
                  <Form.Item
                    label='Company URL'
                    name='companyUrl'
                    rules={[{ type: 'string', required: true }]}
                  >
                    <Input placeholder='Company URL' />
                  </Form.Item>
                  <Form.Item
                    label='Additional information'
                    name='additionalInfo'
                    rules={[{ type: 'string' }]}
                  >
                    <Input.TextArea
                      rows={4}
                      placeholder='Add additional information about the company here.'
                    />
                  </Form.Item>
                </div>
                <Form.Item className='m-0 self-end'>
                  <Button
                    iconPosition='end'
                    icon={<RiArrowRightSLine className='size-5' />}
                    htmlType='submit'
                    type='primary'
                  >
                    Let's begin
                  </Button>
                </Form.Item>
              </div>
            </Form>
            <div className='flex flex-col'>
              <Divider>Other apps</Divider>
              <div className='flex items-start justify-center gap-8'>
                <Tooltip title='I want to find matching grants'>
                  <div
                    className='flex w-20 cursor-pointer flex-col items-center gap-2'
                    onClick={() => {
                      setMode(GrantApplicationMode.MATCHING)
                      setCurrentStep(0)
                      setSelectedConversation(generateUUID())
                    }}
                  >
                    <div className='flex !size-14 items-center justify-center rounded-2xl bg-primary/30 transition-all hover:scale-110 dark:bg-dark-primary/30'>
                      <RiSearchLine className='size-8 text-primary/80 dark:text-dark-on-primary' />
                    </div>
                    <p className='text-center text-sm opacity-85'>
                      Find grants
                    </p>
                  </div>
                </Tooltip>
                <Tooltip title='I want to research something specific'>
                  <div
                    className='flex w-20 cursor-pointer flex-col items-center gap-2'
                    onClick={() => {
                      setMode(GrantApplicationMode.RESEARCHING)
                      setCurrentStep(10)
                      setSelectedConversation(generateUUID())
                    }}
                  >
                    <div className='flex !size-14 items-center justify-center rounded-2xl bg-primary/30 transition-all hover:scale-110 dark:bg-dark-primary/30'>
                      <RiChatPollLine className='size-8 text-primary/80 dark:text-dark-on-primary' />
                    </div>
                    <p className='text-center text-sm opacity-85'>
                      Research mode
                    </p>
                  </div>
                </Tooltip>
                <Tooltip title='I have an existing grant I want to refine'>
                  <div
                    className='flex w-20 cursor-pointer flex-col items-center gap-2'
                    onClick={() => {
                      setMode(GrantApplicationMode.CONTINUE_EXISTING)
                      setCurrentStep(7)
                      setSelectedConversation(generateUUID())
                    }}
                  >
                    <div className='flex !size-14 items-center justify-center rounded-2xl bg-primary/30 transition-all hover:scale-110 dark:bg-dark-primary/30'>
                      <RiContractLine className='size-8 text-primary/80 dark:text-dark-on-primary' />
                    </div>
                    <p className='text-center text-sm opacity-85'>
                      Redrafting assistant
                    </p>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='flex size-full overflow-y-auto'>
      <div className='m-auto flex flex-col gap-12'>
        <div
          className='relative flex cursor-pointer flex-col items-center gap-2'
          onClick={() => {
            setMode(GrantApplicationMode.DRAFTING)
            setCurrentStep(0)
            setSelectedConversation(generateUUID())
          }}
        >
          <Tooltip
            title="I'm ready to find, match and write a grant from start to finish. This is your all-in-one grant assistant."
            placement='right'
            className='flex flex-col items-center gap-4'
          >
            <div className='flex !size-52 items-center justify-center overflow-hidden rounded-3xl bg-primary/30 p-4 transition-all hover:scale-105 dark:bg-dark-primary/30'>
              <div className='relative left-2 top-3 m-auto scale-[170%]'>
                <Lottie
                  options={{ animationData: lottieChatLoadingScreenAnimation }}
                />
              </div>
            </div>
            <p className='text-center text-lg font-semibold'>SmartGrants.ai</p>
          </Tooltip>
        </div>

        <div className='flex flex-col gap-5'>
          <Divider className='!text-sm !font-normal opacity-70'>
            Other apps
          </Divider>
          <div className='grid grid-cols-3 gap-1 self-center sm:gap-6'>
            <Tooltip title='I want to find matching grants'>
              <div
                className='flex cursor-pointer flex-col items-center gap-3'
                onClick={() => {
                  setMode(GrantApplicationMode.MATCHING)
                  setCurrentStep(0)
                  setSelectedConversation(generateUUID())
                }}
              >
                <div className='flex !size-20 items-center justify-center rounded-3xl bg-primary/30 transition-all hover:scale-110 dark:bg-dark-primary/30'>
                  <RiSearchLine className='size-12 text-primary/80 dark:text-dark-on-primary' />
                </div>
                <p className='text-center text-sm'>Find grants</p>
              </div>
            </Tooltip>
            <Tooltip title='I want to research something specific'>
              <div
                className='flex cursor-pointer flex-col items-center gap-3'
                onClick={() => {
                  setMode(GrantApplicationMode.RESEARCHING)
                  setCurrentStep(10)
                  setSelectedConversation(generateUUID())
                }}
              >
                <div className='flex !size-20 items-center justify-center rounded-3xl bg-primary/30 transition-all hover:scale-110 dark:bg-dark-primary/30'>
                  <RiChatPollLine className='size-12 text-primary/80 dark:text-dark-on-primary' />
                </div>
                <p className='text-center text-sm'>Research mode</p>
              </div>
            </Tooltip>
            <Tooltip title='I have an existing grant I want to refine'>
              <div
                className='flex cursor-pointer flex-col items-center gap-3'
                onClick={() => {
                  setMode(GrantApplicationMode.CONTINUE_EXISTING)
                  setCurrentStep(7)
                  setSelectedConversation(generateUUID())
                }}
              >
                <div className='flex !size-20 items-center justify-center rounded-3xl bg-primary/30 transition-all hover:scale-110 dark:bg-dark-primary/30'>
                  <RiContractLine className='size-12 text-primary/80 dark:text-dark-on-primary' />
                </div>
                <p className='text-center text-sm'>Redrafting assistant</p>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InitialStep
