import { useContext } from 'react'

import { AgentContext } from '@/context/agent/agent.context'

const useAgentState = () => {
  const context = useContext(AgentContext)
  if (!Object.keys(AgentContext).length) {
    throw new Error('AgentContext must be used within its provider')
  }
  return context
}

export default useAgentState
