'use client'

import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'

import CompanyInfoStep from '@/components/Matcher/Steps/CompanyInfoStep'
import FeedbackStep from '@/components/Matcher/Steps/FeedbackStep'
import ProtectedGroupStep from '@/components/Matcher/Steps/ProtectedGroupStep'
import SpecificProjectStep from '@/components/Matcher/Steps/SpecificProjectStep'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { stepsData, stepsDataRedrafting } from '@/constants/matcher'
import { getAnswer, getDrafts, stopAnswering } from '@/service/Chatbot'
import { getGrantsPrompt } from '@/utils/prompts'

import GrantEditor from './GrantEditor'
import InitialStep from './InitialStep'
import StepHeader from './StepHeader'
import AgentResponseStep from './Steps/AgentResponseStep'
import GrantInfoStep from './Steps/GrantInfoStep'
import PreferredGrantsStep from './Steps/PreferredGrantsStep'
import SelectGrantStep from './Steps/SelectGrantStep'
import UploadDocumentStep from './Steps/UploadDocumentStep'
import UploadGrantStep from './Steps/UploadGrantStep'
import lottieChatLoadingScreenAnimation from '../../../public/lottieChatLoadingScreen.json'

import { IQuestion } from '@/types/chatbot'
import { GrantApplicationMode } from '@/types/grants'

const Matcher: React.FC = () => {
  const { agents } = useAgents()
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const [finishedSteps, setFinishedSteps] = useState(0)
  const [progressException, setProgressException] = useState(false)
  const { selectedConversation, setSelectedConversation, mutateConversations } =
    useDrawerState()
  const {
    currentStep,
    questions,
    steps,
    mode,
    setQuestions,
    setCurrentStep,
    setMode,
    setSections,
    setSteps,
  } = useGrantApplicationState()

  useEffect(() => {
    stopAnswering(true, setLoading)
  }, [selectedConversation])

  const gatherGrants = async (
    additionalInfo?: string,
    tmpQuestions?: IQuestion[]
  ) => {
    if (!agents) return

    setLoading(true)

    const prompt = getGrantsPrompt(
      steps[0],
      steps[2]?.additionalInfo,
      steps[4],
      steps[5]?.preferredGrants,
      steps[3],
      additionalInfo
    )

    await getDrafts(
      true,
      prompt,
      tmpQuestions ?? questions,
      agents,
      setLoading,
      setQuestions,
      t,
      setProgressException,
      setFinishedSteps,
      user?.email,
      selectedConversation
    )
  }

  const updateConversationTitle = async () => {
    const config = {
      method: 'put',
      withCredentials: true,
      ...configHeader,
      data: JSON.stringify({
        titlePrompt: `Title should include the company name or any potential grant information.`,
      }),
    }

    try {
      await axios(`${API_URL}/v2/conversations/${selectedConversation}`, config)
      mutateConversations()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (
      !loading &&
      (currentStep === 2 || currentStep === 6 || currentStep === 7)
    ) {
      updateConversationTitle()
    }
  }, [loading])

  const reset = () => {
    setCurrentStep(-1)
    setFinishedSteps(0)
    setProgressException(false)
    setQuestions([])
    setMode(undefined)
    setSelectedConversation(undefined)
  }

  const retry = async (additionalInfo?: string) => {
    setProgressException(false)
    setFinishedSteps(0)
    let newQuestions = questions
    if (steps[currentStep]?.numQuestions) {
      newQuestions = questions.slice(0, -steps[currentStep].numQuestions)
    }
    setQuestions(newQuestions)
    await gatherGrants(additionalInfo, newQuestions)
  }

  const getAnswerWrapper = async (
    question: string,
    streaming: boolean,
    json?: boolean
  ) => {
    if (!agents || !setQuestions) return

    let numRetries = 0
    let success = false
    while (numRetries < 3 && !success) {
      try {
        await getAnswer(
          true,
          question,
          questions,
          agents,
          setLoading,
          setQuestions,
          t,
          false,
          user?.email,
          undefined,
          undefined,
          selectedConversation,
          streaming,
          undefined,
          undefined,
          undefined,
          json
        )
        success = true
      } catch {
        numRetries++
      }
    }
  }

  const goBack = () => {
    if (steps[currentStep]?.numQuestions) {
      setQuestions(questions.slice(0, -steps[currentStep].numQuestions))
    }
    setSteps({
      ...steps,
      [currentStep]: undefined,
    })
    setFinishedSteps(0)

    if (
      currentStep === 0 ||
      (currentStep === 7 && mode === GrantApplicationMode.CONTINUE_EXISTING)
    ) {
      reset()
    } else {
      if (currentStep === 6) {
        setProgressException(false)
      } else if (currentStep === 8) {
        setSections({
          sections: [],
          history: [],
          historyIndex: 0,
        })
      }
      setCurrentStep(currentStep - 1)
    }
  }

  if (
    mode === GrantApplicationMode.DRAFTING ||
    mode === GrantApplicationMode.MATCHING
  ) {
    return (
      <div className='flex size-full flex-col'>
        <StepHeader
          title={stepsData[currentStep]?.title}
          tooltip={stepsData[currentStep]?.tooltip}
        />
        {currentStep === 0 ? (
          <CompanyInfoStep goBack={goBack} />
        ) : currentStep === 1 ? (
          <UploadDocumentStep
            getAnswerWrapper={getAnswerWrapper}
            goBack={goBack}
          />
        ) : currentStep === 2 ? (
          <AgentResponseStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 3 ? (
          <ProtectedGroupStep goBack={goBack} />
        ) : currentStep === 4 ? (
          <SpecificProjectStep goBack={goBack} />
        ) : currentStep === 5 ? (
          <PreferredGrantsStep gatherGrants={gatherGrants} goBack={goBack} />
        ) : currentStep === 6 ? (
          <SelectGrantStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            setLoading={setLoading}
            retry={retry}
            progressException={progressException}
            setProgressException={setProgressException}
            finishedSteps={finishedSteps}
            setFinishedSteps={setFinishedSteps}
            goBack={goBack}
          />
        ) : currentStep === 7 ? (
          <GrantInfoStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 8 ? (
          <GrantEditor
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 9 ? (
          <FeedbackStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 10 ? (
          <div className='pointer-events-none m-auto size-full max-w-[300px] opacity-80'>
            <Lottie
              options={{ animationData: lottieChatLoadingScreenAnimation }}
            />
          </div>
        ) : null}
      </div>
    )
  }

  if (mode === GrantApplicationMode.CONTINUE_EXISTING) {
    return (
      <div className='flex size-full flex-col overflow-y-auto'>
        <StepHeader
          title={stepsDataRedrafting[currentStep]?.title}
          tooltip={stepsDataRedrafting[currentStep]?.tooltip}
        />
        {currentStep === 7 ? (
          <UploadGrantStep
            mutateConversations={mutateConversations}
            setLoading={setLoading}
            goBack={goBack}
          />
        ) : currentStep === 8 ? (
          <GrantEditor
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 9 ? (
          <FeedbackStep
            getAnswerWrapper={getAnswerWrapper}
            loading={loading}
            goBack={goBack}
          />
        ) : currentStep === 10 ? (
          <div className='pointer-events-none m-auto size-full max-w-[300px] opacity-80'>
            <Lottie
              options={{ animationData: lottieChatLoadingScreenAnimation }}
            />
          </div>
        ) : null}
      </div>
    )
  }

  return <InitialStep />
}

export default Matcher
