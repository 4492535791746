import {
  RemixiconComponentType,
  RiBankCardLine,
  RiGlobalLine,
  RiGroupLine,
  RiQuestionnaireLine,
  RiShieldFlashLine,
  RiSparklingFill,
} from '@remixicon/react'
import { compiler as mdCompiler } from 'markdown-to-jsx'

import { Section, Subsection } from '@/types/document'

export const iconMap: Record<string, RemixiconComponentType> = {
  RiGlobalLine,
  RiShieldFlashLine,
  RiBankCardLine,
  RiGroupLine,
  RiQuestionnaireLine,
  RiSparklingFill,
}

export const commands = [
  {
    command: '/config',
    description: 'This command allows you to configure the selected agent.',
  },
  {
    command: '/email',
    description:
      'This command allows you send an email. You must specify recipient email and message content',
  },
  {
    command: '/event',
    description:
      'This command allows you to create a Google Calendar event. You must specify start time, end time, description and summary. Optionally attendees.',
  },
  {
    command: '/export',
    description: 'This command allows you to export the chat to google docs.',
  },
]

export const markdown = (
  s: string,
  loading?: boolean,
  options?: any
): JSX.Element => {
  let str = s
  if (loading) {
    str = `${s.trim()}<span className='animate-ping font-black'>│</span>`
  }

  // Change links to open in new tab
  const content = linksToOpenInNewTab(str)

  // render markdown
  return mdCompiler(content, {
    wrapper: null,
    ...options,
  })
}

export const linksToOpenInNewTab = (s: string): string =>
  s.replace(
    /\[([^\]]+)\]\((https?:\/\/[^\s)]+\/?)\)|\b(https?:\/\/[^\s]+\/?)\b/g,
    (match, p1, p2, p3) => {
      if (p2) {
        return `<a href="${p2}" target="_blank">${p1}</a>`
      } else if (p3) {
        return `<a href="${p3}" target="_blank">${p3}</a>`
      }
      return match
    }
  )

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function sectionToString(section: Section | Subsection): string {
  let sec = {
    id: section.id,
    title: section.title,
    text: section.text,
    references: section.references,
  } as {
    id: string
    title: string
    text: string
    references: string[]
    subsections?: Subsection[] | undefined
  }

  if ('subsections' in section) {
    sec = {
      ...sec,
      subsections: section.subsections?.map((subsection) => ({
        id: subsection.id,
        title: subsection.title,
        text: subsection.text,
        references: subsection.references,
      })),
    }
  }
  return JSON.stringify(sec)
}

export function countWords(str: string): number {
  return str
    .trim()
    .split(' ')
    .filter(function (n) {
      return n != ''
    }).length
}
