import { createContext } from 'react'

import { ModalsProps } from './modals.types'

interface IModalsInitialState {
  openModal: string
  reexecuteQuery: {
    sections?: () => void
    documents?: () => void
    users?: () => void
    raptor?: () => void
    knowledgeGraph?: () => void
    organizations?: () => void
  }
}

export const initialState: IModalsInitialState = {
  openModal: '',
  reexecuteQuery: {
    documents: () => {
      return undefined as void
    },
    users: () => {
      return undefined as void
    },
    raptor: () => {
      return undefined as void
    },
    knowledgeGraph: () => {
      return undefined as void
    },
    organizations: () => {
      return undefined as void
    },
  },
}

export const ModalsContext = createContext<ModalsProps>({
  ...initialState,
  toggleModal: () => undefined,
  setReexecuteFunctions: () => undefined,
})
