import { sectionToString } from '..'

import { Section } from '@/types/document'

export const generateGraphPrompt = (
  section: Section,
  sections?: Section[],
  graphInstructions?: string
) =>
  `<WHOLE_FILE_CONTEXT>
${sections?.map((s) => sectionToString(s)).join('\n')}
</WHOLE_FILE_CONTEXT>


TASK:
Your goal is to generate a graph based on the data provided in the section below. The graph should be visually appealing and should accurately represent the data provided.

${sectionToString(section)}


ENHANCEMENT INSTRUCTIONS:
- Follow these specific guidelines for enhancement: ${graphInstructions}`
