import axios from 'axios'
import useSWR from 'swr'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { Agent } from '@/types'

const useAgent = (id?: string) => {
  const config = {
    method: 'get',
    withCredentials: true,
    ...configHeader,
  }

  const { data, error, isLoading, mutate } = useSWR(
    id ? ['agent', id] : null,
    async () => axios(`${API_URL}/agent/${id}`, config),
    { revalidateOnFocus: false }
  )

  return {
    agent: data?.data.agent as Agent,
    isLoading,
    error,
    mutate,
  }
}

export default useAgent
