'use client'

import { RiArrowDownSLine, RiArrowUpSLine } from '@remixicon/react'
import { Button, Card } from 'antd'
import Link from 'next/link'
import { useState } from 'react'

import { cn } from '@/utils/clsx'

interface SelectGrantCardProps {
  index: number
  name?: string
  funder?: string
  email?: string
  url?: string
  geography?: string
  type?: string
  range?: string
  alignment?: string
  eligibility?: string
  deadline?: string
  selectedGrant?: number
  setSelectedGrant: (selectedGrant?: number) => void
}

const SelectGrantCard: React.FC<SelectGrantCardProps> = ({
  index,
  name,
  funder,
  email,
  url,
  geography,
  type,
  range,
  alignment,
  eligibility,
  deadline,
  selectedGrant,
  setSelectedGrant,
}) => {
  const [viewMore, setViewMore] = useState(false)

  return (
    <Card
      className={cn(
        'bg-surface dark:bg-dark-surface cursor-pointer',
        index === selectedGrant ? 'bg-primary/40 dark:bg-dark-primary/40' : ''
      )}
      onClick={() =>
        selectedGrant === index
          ? setSelectedGrant(undefined)
          : setSelectedGrant(index)
      }
    >
      <div className='flex flex-col gap-4'>
        <div className='text-lg font-bold'>{name}</div>
        <div className='flex flex-col'>
          <ul className='list-disc'>
            <li>
              <span className='font-bold'>Grant Range:</span>{' '}
              {!range || range === '' ? 'N/A' : range}
            </li>
            <li>
              <span className='font-bold'>Application Deadline:</span>{' '}
              {!deadline || deadline === '' ? 'N/A' : deadline}
            </li>
            {viewMore && (
              <>
                <li>
                  <span className='font-bold'>Funder:</span>{' '}
                  {!funder || funder === '' ? 'N/A' : funder}
                </li>
                <li>
                  <span className='font-bold'>Email:</span>{' '}
                  {!email || email === '' ? 'N/A' : email}
                </li>
                {url && (
                  <li>
                    <span className='font-bold'>URL:</span>{' '}
                    <Link href={url} target='_blank' rel='noreferrer'>
                      {url}
                    </Link>
                  </li>
                )}
                <li>
                  <span className='font-bold'>Geographic scope:</span>{' '}
                  {!geography || geography === '' ? 'N/A' : geography}
                </li>
                <li>
                  <span className='font-bold'>Type of Organization:</span>{' '}
                  {!type || type === '' ? 'N/A' : type}
                </li>
                <li>
                  <span className='font-bold'>Alignment:</span>{' '}
                  {!alignment || alignment === '' ? 'N/A' : alignment}
                </li>
                <li>
                  <span className='font-bold'>Eligibility Requirements:</span>{' '}
                  {!eligibility || eligibility === '' ? 'N/A' : eligibility}
                </li>
              </>
            )}
            <Button
              type='link'
              onClick={(e) => {
                e.stopPropagation()
                setViewMore(!viewMore)
              }}
              iconPosition='end'
              icon={
                viewMore ? (
                  <RiArrowUpSLine className='size-5' />
                ) : (
                  <RiArrowDownSLine className='size-5' />
                )
              }
              className='self-start !border-0 !p-0 text-on-surface/70 hover:!text-on-surface dark:text-dark-on-surface/70 dark:hover:!text-dark-on-surface'
            >
              {viewMore ? 'Show less' : 'Show more'}
            </Button>
          </ul>
        </div>
      </div>
    </Card>
  )
}

export default SelectGrantCard
