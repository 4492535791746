import axios from 'axios'
import useSWR from 'swr'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

export type IOrganization = {
  id: string
  name: string
  agentId: string
  agentName: string
}

const useOrganization = (id?: string) => {
  const config = {
    method: 'get',
    withCredentials: true,
    ...configHeader,
  }

  const { data, error, isLoading, mutate } = useSWR(
    id ? ['organization', id] : null,
    async () => axios(`${API_URL}/organization/${id}`, config)
  )

  if (error) {
    console.error(error)
  }

  return {
    organization: data?.data.organization as IOrganization,
    isLoading,
    error,
    mutate,
  }
}

export default useOrganization
