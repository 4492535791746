'use client'

import { useSearchParams } from 'next/navigation'
import { createContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useAgentState from '@/hooks/context/useAgentState'
import useDrawerState from '@/hooks/context/useDrawerState'
import useTourState from '@/hooks/context/useTourState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'
import useConversation from '@/hooks/useConversation'
import useSettings from '@/hooks/useSettings'

import QuestionInput from '@/components/Chatbot/QuestionInput'

import { DEFAULT_USE_CONTACT_US_CTA } from '@/constants/env'
import {
  getAnswer,
  mapConversation,
  stopAnswering,
  uploadDocumentsAsMessage,
} from '@/service/Chatbot'
import { cn } from '@/utils/clsx'

import Answers from './Chatbot/Output/Answers'
import ContactUsModal from './Chatbot/Output/ContactUsModal'
import UnderMaintenance from './UnderMaintenance'

import { Swarm } from '@/types'
import { IQuestion } from '@/types/chatbot'

export const IndexPageContext = createContext(false)

interface ChatbotProps {
  indexPage?: boolean
  hideHeader?: boolean
  adminChatbot?: boolean
  swarm?: Swarm
  className?: string
}

const Chatbot: React.FC<ChatbotProps> = ({
  indexPage,
  hideHeader,
  adminChatbot,
  swarm,
  className,
}) => {
  const { t } = useTranslation()
  const searchParams = useSearchParams()
  const { selectedConversation, mutateConversations } = useDrawerState()
  const { startTour } = useTourState()

  const { user } = useAuth()
  const { conversation, error: conversationError } =
    useConversation(selectedConversation)
  const {
    agents,
    selectedAgentAdmin,
    error: agentsError,
  } = useAgents(undefined, undefined, undefined, user?.email)
  const { agent, queryContext } = useAgentState()
  const currentAgent = adminChatbot ? selectedAgentAdmin : agent
  const { settings, mutate } = useSettings(currentAgent?.id ?? '')

  const [questions, setQuestions] = useState([] as IQuestion[])
  const [loading, setLoading] = useState(false)
  const [fileList, setFileList] = useState<FileList | null>(null)
  const [dragging, setDragging] = useState<boolean>(false)
  const answersRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (user?.role === 'USER') {
      startTour('userTour')
    }
  }, [user])

  // when conversation is loaded, map it to questions
  useEffect(() => {
    if (conversation && agents && !conversationError && !agentsError) {
      setQuestions(mapConversation(conversation, agents))
    }
  }, [conversation, agents])

  // get answer when all necessary data is loaded
  useEffect(() => {
    if (
      !!settings &&
      !!selectedAgentAdmin &&
      searchParams.get('question') &&
      !loading
    ) {
      getAnswerWrapper(true, searchParams.get('question')!)
    }
  }, [settings, selectedAgentAdmin, searchParams])

  const getAnswerWrapper = async (
    domain: boolean,
    question: string,
    agentId?: string,
    isCommand = false
  ) => {
    const newQuestions: IQuestion[] | undefined =
      await uploadDocumentsAsMessage(
        fileList,
        t,
        setFileList,
        questions,
        selectedConversation,
        user?.email,
        agentId ?? currentAgent?.id,
        queryContext
      )

    try {
      await getAnswer(
        domain,
        question,
        newQuestions ?? questions,
        agents,
        setLoading,
        setQuestions,
        t,
        isCommand,
        user?.email,
        currentAgent?.id,
        queryContext,
        selectedConversation,
        settings?.useStreaming,
        settings?.useDrafting,
        adminChatbot,
        swarm
      )
    } catch (e) {
      console.error('Error getting answer', e)
    }

    mutateConversations()

    if (isCommand) {
      mutate()
    }
  }

  if (conversationError || agentsError) {
    return <UnderMaintenance onRefreshClick={() => location.reload()} />
  }

  return (
    <IndexPageContext.Provider value={!hideHeader ? !!indexPage : false}>
      <main
        className={cn(
          'relative flex flex-col bg-background dark:bg-dark-background shadow-md',
          {
            rounded: !hideHeader,
            'sm:h-auto': !indexPage,
            '': !adminChatbot,
          }
        )}
        onDragOver={(e) => {
          e.preventDefault()
          setDragging(true)
        }}
        onDragLeave={() => setDragging(false)}
        onDragEnd={() => setDragging(false)}
        onDrop={() => setDragging(false)}
      >
        <div className={className}>
          <Answers
            questions={questions}
            getAnswer={getAnswerWrapper}
            hideHeader={hideHeader}
            adminChatbot={adminChatbot}
            isCtaOpen={DEFAULT_USE_CONTACT_US_CTA}
            onSelectedDraft={(draft) => {
              const tmpQuestions = [...questions]
              const question = tmpQuestions[tmpQuestions.length - 1]
              const message = question!.messages[question!.messages.length - 1]
              if (message?.message) {
                message.message = draft
              }
              setQuestions([...tmpQuestions])
            }}
            agents={agents}
            setQuestions={setQuestions}
            loading={loading}
            answersRef={answersRef}
          />
          {!!settings && (
            <QuestionInput
              getAnswer={getAnswerWrapper}
              stopAnswering={() =>
                stopAnswering(settings.useStreaming, setLoading)
              }
              loading={loading}
              adminChatbot={adminChatbot}
              settings={settings}
              showSuggestedQuestions={questions.length === 0}
              setFileList={setFileList}
              fileList={fileList}
              dragging={dragging}
              agent={currentAgent}
              isChatEmpty={questions.length === 0}
              answersRef={answersRef}
              answersChangeIndicator={questions}
              questions={questions}
              setQuestions={setQuestions}
              mutateConversations={mutateConversations}
            />
          )}
        </div>
      </main>

      {DEFAULT_USE_CONTACT_US_CTA && (
        <ContactUsModal
          chat={questions.map(({ question, messages }) => ({
            question,
            message: messages[1]?.message ?? '',
          }))}
        />
      )}
    </IndexPageContext.Provider>
  )
}

export default Chatbot
