'use client'

import { RiSparklingFill } from '@remixicon/react'
import { useEffect, useRef, useState } from 'react'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import { markdown } from '@/utils'
import { cn } from '@/utils/clsx'
import { enhanceCompanyInfoPrompt } from '@/utils/prompts'

import LoadingScreen from '../LoadingScreen'
import ProgressButtons from '../ProgressButtons'
import TextAreaWithUpload from '../TextAreaWithUpload'
import lottieSearchingAnimation from '../../../../public/lottieSearching.json'

interface AgentResponseStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  loading?: boolean
  goBack: () => void
}

const AgentResponseStep: React.FC<AgentResponseStepProps> = ({
  getAnswerWrapper,
  loading,
  goBack,
}) => {
  const { questions, steps, setCurrentStep, currentStep, setSteps } =
    useGrantApplicationState()
  const [numQuestions, setNumQuestions] = useState<number>(0)
  const [enhancing, setEnhancing] = useState(false)
  const answerRef = useRef<HTMLDivElement>(null)
  const additionalInfoRef = useRef<string>('')

  useEffect(() => {
    if (!numQuestions && steps[currentStep]?.numQuestions) {
      setNumQuestions(steps[currentStep]?.numQuestions ?? 1)
    }
    additionalInfoRef.current = steps[currentStep]?.additionalInfo ?? ''
  }, [steps[currentStep]])

  useEffect(() => {
    if (!loading && questions[questions.length - 1]?.messages[1]?.message) {
      const newNumQuestions = (numQuestions ?? 1) + 1
      setNumQuestions(newNumQuestions)
      setSteps({
        ...steps,
        [currentStep]: {
          ...steps[currentStep],
          numQuestions: newNumQuestions,
        },
      })
      additionalInfoRef.current = steps[currentStep]?.additionalInfo ?? ''
      setEnhancing(false)
    }
  }, [loading])

  const finish = async () => {
    setSteps({
      ...steps,
      [currentStep]: {
        ...steps[currentStep],
        additionalInfo: additionalInfoRef.current,
      },
    })
    setCurrentStep(currentStep + 1)
  }

  const enhance = async (values: { input: string }) => {
    setEnhancing(true)
    await getAnswerWrapper(
      enhanceCompanyInfoPrompt(
        questions[questions.length - 1]?.messages[1]?.message,
        steps[0],
        values.input
      ),
      true
    )
  }

  return (
    <div className='flex h-full grow flex-col'>
      <div
        className='flex h-[calc(100vh-110px)] w-full overflow-y-auto p-2'
        ref={answerRef}
      >
        <div
          id='scroller'
          className={cn(
            'm-auto flex w-full min-h-full flex-col rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface',
            !questions[questions.length - 1]?.messages[1]?.message
              ? 'h-full'
              : ''
          )}
        >
          {questions[questions.length - 1]?.messages[1]?.message ? (
            <>
              <div className='markdown-answer mb-6 grow break-words text-left text-sm sm:text-base'>
                {markdown(
                  questions[questions.length - 1]?.messages[1]
                    ?.message as string,
                  loading
                )}
              </div>
              {!loading && (
                <TextAreaWithUpload
                  inputRef={additionalInfoRef}
                  onFinish={enhance}
                  finishIcon={<RiSparklingFill className='size-5' />}
                  finishText='Enhance'
                  formErrorMessage='Please add enhancement instructions.'
                  placeholder='How would you like to enhance this information?'
                  uploadTooltip='Upload files'
                />
              )}
              <div id='anchor' className='opacity-0'>
                a
              </div>
            </>
          ) : (
            <LoadingScreen
              lottieAnimation={lottieSearchingAnimation}
              text={[
                'Analyzing web sources for relevant information...',
                'Thinking...',
                'Gathering detailed company insights...',
                'Thinking...',
                'Compiling comprehensive company details...',
              ]}
              timeInterval={10000}
              infiniteLoader
            />
          )}
        </div>
      </div>
      <ProgressButtons
        containerRef={answerRef}
        changeIndicator={questions[questions.length - 1]?.messages[1]?.message}
        goBack={goBack}
        goNext={finish}
        disabledBack={
          enhancing ||
          !questions[questions.length - 1]?.messages[1]?.message ||
          loading
        }
        disabledNext={
          enhancing ||
          !questions[questions.length - 1]?.messages[1]?.message ||
          loading
        }
      />
    </div>
  )
}

export default AgentResponseStep
