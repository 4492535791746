import { dateFormat, datetimeFormat } from '@/branding-config'

export const grantAlignmentPrompt = (selectedGrant?: string) =>
  `${selectedGrant ? `User selected grant: ${selectedGrant}` : 'Select one grant that most closely aligns with my company.'}

Provide the real URL that links to the selected grant.

Cover all the following points in your response:
- Areas of Interest: provide a detailed description of the grant and the funder. Include the funder's name, email, and website URL.
- Restrictions details: provide a detailed description of the grant's restrictions, including the geography, type, range, alignment, and eligibility.
- Application procedures: provide a detailed description of the application procedures, including the deadline for applying.
- How my company specifically meets each of the grant criteria: be extremely specific, including facts and data about the grant and the company.

Provide a highly compelling and detailed alignment between my company's mission and the funder’s vision. Demonstrate a deep understanding of the funder’s long-term goals and make a persuasive case for how my company's programs and values directly support the funder’s overarching objectives. The connection must be seamless and reinforce the potential for a strong, mission-driven partnership.

Demonstrate a precise and compelling alignment between my company's service area and the funder's geographic focus. The proposal must not only highlights the geographic fit but also make a strong case for why my company's location is particularly well-suited to fulfill the funder's goals. The geographic alignment must be seamless and clearly strengthen the overall case for funding.

Offer a compelling and well-supported case for the alignment between my company's target population and the funder's specific focus. The narrative must demonstrate a deep understanding of the population's needs and the funder's goals, making a strong case for why this alignment is critical to achieving impact. The connection must be seamless and persuasive, leaving no gaps in the alignment.

Provide a highly compelling case for why my company's request for support aligns perfectly with the funder's offerings. The type of funding sought—whether operating, programmatic, capacity building, or capital—must be thoroughly justified, and the match between my company's needs and the funder's preferences must be seamless and well-argued.
Thoroughly Justify the Type of Funding Sought:
- Specify the exact type of funding the company is seeking (operating, programmatic, capacity building, or capital).
- Explain in detail why this type of funding is essential for the company's mission and goals.
- Describe how the funds will be utilized and the impact they will have on company's operations or projects.
Demonstrate a Seamless Match Between Company Needs and the Funder's Preferences:
- Highlight specific aspects of the funder's offerings that align with company's funding needs.
- Show a deep understanding of the funder's mission, goals, and funding priorities.
- Argue convincingly how supporting the company helps the funder achieve their objectives.

Provide a highly detailed and compelling analysis of the funder's past grantees and historical giving patterns. Draw clear parallels between my company and previously funded organizations or projects, making a strong case for why the funder is likely to support the current request. The alignment with the funder's giving history must be seamless, reinforcing the likelihood of funding.

List out the typical grant award size for companies based on available data on the web, and include the deadline for applying. Use citations.

<TOOL>
If the selected grant is private (NOT FEDERAL or STATE), use the searchAndScrape function to find the EIN number of (Example Query: "<Funder-Name> EIN").
If the searchAndScrape function successfully finds the EIN number, use the EIN number to call candid function to find the funder's name, email, and website URL.
Use the information retrieved from candid function to construct a better alignment response (based on the historical giving patterns).
If EIN number is not found, mention in your response that if the user can provide the EIN number, you can provide a more detailed alignment response (also give him the link to this site where he can search for it https://apps.irs.gov/app/eos/).
</TOOL>

FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}
YOU MUST RESPOND IN MARKDOWN FORMAT.
USE INLINE CITATIONS IN TEXT FOR ALL FACTS, DATA, AND INFORMATION THAT IS RETRIEVED FROM CONTEXT DOCUMENTS, MESSAGE HISTORY OR WEB SEARCHES!!!
THERE MUST BE AT LEAST 1 CITATION PER PARAGRAPH.`
