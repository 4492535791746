import axios from 'axios'
import useSWR from 'swr'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

export interface DbSettings {
  openAiUseAzureDeployment: boolean
  storeSearchAndScrapeResponses: boolean
  requireSubscription: boolean
  documensoTemplateId: number
  documensoRecipientId: number
  useDocumenso: boolean
  useConversationDocumentsInContext: boolean
  useMinio: boolean
  userMustBeInvited: boolean
  showExportChat: boolean
}

export const mapSettings = (data: any): DbSettings => {
  return {
    openAiUseAzureDeployment: data['OPENAI_USE_AZURE_DEPLOYMENT']! === 'true',
    storeSearchAndScrapeResponses:
      data['STORE_SEARCH_AND_SCRAPE_RESPONSES']! === 'true',
    requireSubscription: data['REQUIRE_SUBSCRIPTION']! === 'true',
    documensoTemplateId: parseInt(data['DOCUMENSO_TEMPLATE_ID']!),
    documensoRecipientId: parseInt(data['DOCUMENSO_RECIPIENT_ID']!),
    useDocumenso: data['USE_DOCUMENSO']! === 'true',
    useConversationDocumentsInContext:
      data['USE_CONVERSATION_DOCUMENTS_IN_CONTEXT']! === 'true',
    useMinio: data['USE_MINIO']! === 'true',
    userMustBeInvited: data['USER_MUST_BE_INVITED']! === 'true',
    showExportChat: data['SHOW_EXPORT_CHAT']! === 'true',
  }
}

const useDbSettings = () => {
  const config = {
    method: 'get',
    withCredentials: true,
    ...configHeader,
  }

  const { data, error, isLoading, mutate } = useSWR(
    ['dbSettings'],
    async () => axios(`${API_URL}/env-settings`, config),
    { revalidateOnFocus: false }
  )

  if (error) {
    console.error(error)
  }

  return {
    settings: data ? mapSettings(data?.data.settings) : undefined,
    isLoading,
    error,
    mutate,
  }
}

export default useDbSettings
