'use client'

import { useSearchParams } from 'next/navigation'
import { memo, PropsWithChildren, useEffect, useMemo, useState } from 'react'

import useAgent from '@/hooks/useAgent'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'

import { AgentContext } from './agent.context'

export const AgentProvider = memo(({ children }: PropsWithChildren) => {
  const searchParams = useSearchParams()
  const [agentIdState, setAgentIdState] = useState<string>()
  const [queryContext, setQueryContext] = useState<string>()
  const { agent } = useAgent(agentIdState)
  const { user } = useAuth()
  const { selectedAgent } = useAgents(
    undefined,
    undefined,
    undefined,
    user?.email
  )

  useEffect(() => {
    const paramAgentId = searchParams.get('agent')
    if (paramAgentId || selectedAgent) {
      setAgentIdState(paramAgentId ?? selectedAgent.id)
    }
  }, [searchParams.get('agent'), selectedAgent])

  useEffect(() => {
    const paramContext = searchParams.get('context')
    if (paramContext) {
      setQueryContext(paramContext)
    }
  }, [searchParams.get('context')])

  const contextValue = useMemo(() => {
    return {
      agent,
      queryContext,
      setQueryContext,
    }
  }, [agent, queryContext])

  return (
    <AgentContext.Provider value={contextValue}>
      {children}
    </AgentContext.Provider>
  )
})
