'use client'

import { RiAttachment2 } from '@remixicon/react'
import { Button, Form, Input, Tooltip, Upload, UploadFile } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useDrawerState from '@/hooks/context/useDrawerState'
import useThemeState from '@/hooks/context/useThemeState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'

import { colors } from '@/branding-config'
import { stopAnswering, uploadDocuments } from '@/service/Chatbot'
import { cn } from '@/utils/clsx'
import { convertToFileList } from '@/utils/upload'

interface TextAreaWithUploadProps {
  inputRef: React.MutableRefObject<string>
  onFinish: (values: { input: string }) => void
  placeholder?: string
  uploadTooltip?: string
  finishTooltip?: string
  finishIcon?: React.ReactNode
  finishText?: string
  finishType?: 'primary' | 'default' | 'dashed' | 'link' | 'text'
  formErrorMessage?: string
  classname?: string
}

const TextAreaWithUpload: React.FC<TextAreaWithUploadProps> = ({
  inputRef,
  onFinish,
  placeholder,
  uploadTooltip,
  finishTooltip,
  finishIcon,
  finishText,
  finishType,
  formErrorMessage,
  classname,
}) => {
  const { theme } = useThemeState()
  const { selectedAgent } = useAgents()
  const { t } = useTranslation()
  const { selectedConversation } = useDrawerState()
  const { user } = useAuth()
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState<FileList | null>(null)
  const [files, setFiles] = useState<UploadFile[] | undefined>(undefined)
  const [uploading, setUploading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dragging, setDragging] = useState(false)

  useEffect(() => {
    stopAnswering(true, setLoading)
    setFileList(null)
    setFiles(undefined)
    form.resetFields()
  }, [selectedConversation])

  useEffect(() => {
    form.setFieldsValue({ input: inputRef.current })
  }, [inputRef.current, user])

  const handleUpload = async () => {
    if (files) {
      setUploading(true)
      await uploadDocuments(
        fileList,
        selectedAgent.id,
        t,
        setFileList,
        true,
        undefined,
        selectedConversation
      )
      setUploading(false)
    }
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      autoComplete='off'
      requiredMark='optional'
      layout='vertical'
      className='flex w-full flex-col gap-4 text-left'
      onDragOver={(e) => {
        e.preventDefault()
        setDragging(true)
      }}
      onDragLeave={() => setDragging(false)}
      onDragEnd={() => setDragging(false)}
      onDrop={() => setDragging(false)}
      initialValues={{ input: inputRef.current }}
    >
      <div className='flex w-full flex-col'>
        <div
          className={cn(
            'relative flex w-full items-center rounded-lg transition-all duration-300 ease-in-out',
            classname
          )}
          style={{
            border: `1px dashed ${dragging ? colors[theme].on.background : 'transparent'}`,
          }}
        >
          <div className='flex w-full flex-col gap-1'>
            <Dragger
              beforeUpload={() => false}
              onChange={(e) => {
                setFileList(convertToFileList(e.fileList))
                setFiles(e.fileList)
              }}
              multiple
              accept='.doc,.docx,.pdf,.csv'
              className='w-full !p-0'
              openFileDialogOnClick={false}
              fileList={files}
              disabled={loading}
            >
              <div className='flex flex-col gap-1 rounded-lg border border-on-surface/20 bg-background dark:border-dark-on-surface/20 dark:bg-[#141414]'>
                <Form.Item
                  name='input'
                  rules={[
                    {
                      type: 'string',
                      required: true,
                      message: formErrorMessage,
                    },
                  ]}
                  className='!mb-10'
                >
                  <Input.TextArea
                    rows={4}
                    variant='borderless'
                    placeholder={placeholder}
                    className='!resize-none'
                    onChange={(e) => {
                      inputRef.current = e.target.value
                    }}
                  />
                </Form.Item>
                <div className='absolute bottom-0 flex w-full items-center justify-between gap-2 p-2'>
                  <Tooltip title={uploadTooltip}>
                    <Upload
                      multiple
                      accept='.doc,.docx,.pdf,.csv'
                      onChange={(e) => {
                        setFileList(convertToFileList(e.fileList))
                        setFiles(e.fileList)
                      }}
                      disabled={loading}
                      showUploadList={false}
                      className='flex items-center'
                    >
                      <Button icon={<RiAttachment2 className='size-5' />} />
                    </Upload>
                  </Tooltip>
                  <Form.Item className='!m-0 self-end opacity-85'>
                    <Tooltip title={finishTooltip}>
                      <Button
                        htmlType='submit'
                        type={finishType}
                        icon={finishIcon}
                      >
                        {finishText}
                      </Button>
                    </Tooltip>
                  </Form.Item>
                </div>
              </div>
            </Dragger>
          </div>
        </div>
        {fileList && fileList.length > 0 && (
          <Button onClick={handleUpload} disabled={uploading} className='mt-3'>
            {t('upload')}
          </Button>
        )}
      </div>
    </Form>
  )
}

export default TextAreaWithUpload
