'use client'

import { RiInformationLine } from '@remixicon/react'
import { Tooltip } from 'antd'

import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'

import ProgressDots from '@/components/ProgressDots'

import { cn } from '@/utils/clsx'

import { GrantApplicationMode } from '@/types/grants'

interface StepHeaderProps {
  title?: string
  description?: string
  tooltip?: string
}

const StepHeader: React.FC<StepHeaderProps> = ({
  title,
  description,
  tooltip,
}) => {
  const { currentStep, mode } = useGrantApplicationState()

  const totalSteps =
    mode === GrantApplicationMode.MATCHING
      ? 8
      : mode === GrantApplicationMode.DRAFTING
        ? 10
        : 3

  return (
    <div className='flex w-full flex-col gap-2 bg-surface px-4 py-3 text-left text-on-surface shadow-md dark:bg-dark-surface dark:text-dark-on-surface'>
      <div
        className={cn(
          'flex sm:items-center gap-2 w-full flex-col sm:flex-row',
          title || tooltip ? 'justify-between' : 'justify-end'
        )}
      >
        <div className='flex items-center gap-2 pl-10 text-left md:pl-0'>
          {title && <h2 className='truncate text-xl font-bold'>{title}</h2>}
          {tooltip && (
            <Tooltip title={tooltip}>
              <RiInformationLine className='size-5' />
            </Tooltip>
          )}
        </div>
      </div>
      {description && <p className='text-sm'>{description}</p>}

      <ProgressDots
        currentStep={
          mode === GrantApplicationMode.CONTINUE_EXISTING
            ? currentStep - 7
            : currentStep
        }
        totalSteps={totalSteps}
      />
    </div>
  )
}

export default StepHeader
