'use client'

import { memo, PropsWithChildren, useEffect, useMemo, useState } from 'react'

import { SidebarContext } from './sidebar.context'

export const SidebarProvider = memo(({ children }: PropsWithChildren) => {
  const [minimized, setMinimized] = useState(true)

  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage) {
      const minimized = localStorage.getItem('sidebarMinimized')
      if (minimized === 'true') {
        setMinimized(true)
      } else {
        setMinimized(false)
      }
    }
  }, [])
  const toggleSidebar = () => {
    setMinimized(!minimized)
    localStorage.setItem('sidebarMinimized', (!minimized).toString())
  }

  const contextValue = useMemo(() => {
    return {
      minimized,
      toggleSidebar,
    }
  }, [minimized])

  return (
    <SidebarContext.Provider value={contextValue}>
      {children}
    </SidebarContext.Provider>
  )
})
