'use client'

import {
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiCollapseVerticalLine,
  RiExpandVerticalLine,
} from '@remixicon/react'
import { Button, message, Tooltip } from 'antd'
import axios from 'axios'
import { jsonrepair } from 'jsonrepair'
import { useEffect, useRef, useState } from 'react'

import useDrawerState from '@/hooks/context/useDrawerState'
import useGrantApplicationState from '@/hooks/context/useGrantApplicationState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'
import useConversation from '@/hooks/useConversation'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { mapConversation } from '@/service/Chatbot'
import { countWords, generateUUID, markdown } from '@/utils'
import { cn } from '@/utils/clsx'
import { enhance } from '@/utils/grant'
import { generateProposalDraftPrompt } from '@/utils/prompts'

import AddSectionButton from './AddSectionButton'
import GoogleDocsViewer from './GoogleDocsViewer'
import SectionsDragDrop from './SectionsDragDrop'
import LoadingScreen from '../LoadingScreen'
import ProgressButtons from '../ProgressButtons'
import lottieGeneratingDocument from '../../../../public/lottieGeneratingDocument.json'
import lottieScanDocument from '../../../../public/lottieScanDocument.json'

import { Section } from '@/types/document'
import { EnhanceType, GrantApplicationMode } from '@/types/grants'

interface GrantEditorProps {
  goBack: () => void
  getAnswerWrapper: (
    question: string,
    streaming: boolean,
    json?: boolean
  ) => Promise<void>
  loading?: boolean
}

const GrantEditor: React.FC<GrantEditorProps> = ({
  getAnswerWrapper,
  loading,
  goBack,
}) => {
  const { user } = useAuth()
  const {
    currentStep,
    steps,
    questions,
    sections,
    setSections,
    setSteps,
    setQuestions,
    mode,
    isUndoDisabled,
    isRedoDisabled,
    handleUndo,
    handleRedo,
  } = useGrantApplicationState()
  const { selectedConversation } = useDrawerState()
  const { conversation } = useConversation(selectedConversation)
  const { agents, selectedAgent } = useAgents()
  const [editing, setEditing] = useState<{ [key: string]: boolean }>()
  const [enhancing, setEnhancing] = useState<{ [key: string]: boolean }>()
  const [collapsed, setCollapsed] = useState<{ [key: string]: boolean }>()
  const [documentUrl, setDocumentUrl] = useState<string>()
  const [generatingDocument, setGeneratingDocument] = useState<boolean>(false)
  const [initialSectionsStatus, setInitialSectionsStatus] = useState<
    'enhancing' | 'enhanced' | 'enhancingTwice' | 'enhancedTwice'
  >()
  const [finishedSteps, setFinishedSteps] = useState(0)
  const [numRetries, setNumRetries] = useState(2)
  const [references, setReferences] = useState<string[]>([])
  const answerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (
      conversation &&
      questions.length === 0 &&
      mode === GrantApplicationMode.CONTINUE_EXISTING
    ) {
      setQuestions(mapConversation(conversation, agents))
    }
  }, [conversation])

  useEffect(() => {
    setDocumentUrl(steps[currentStep]?.documentUrl)
  }, [steps[currentStep]?.documentUrl])

  useEffect(() => {
    setInitialSectionsStatus(steps[currentStep]?.initialSectionsStatus)
  }, [steps[currentStep]?.initialSectionsStatus])

  useEffect(() => {
    if (sections && sections.length > 0) {
      return
    }

    if (!loading) {
      const text = questions[questions.length - 1]?.messages[1]?.message
      if (text && text !== '') {
        try {
          const json = JSON.parse(
            jsonrepair(text.replaceAll('```json', '').replaceAll('```', ''))
          )
          const newSections = Object.keys(json).map((key: string) => ({
            id: generateUUID(),
            title: json[key]?.title ?? '',
            text: json[key]?.text ?? '',
            references: json[key]?.references ?? [],
            subsections:
              json[key]?.subsections?.map(
                (subsection: {
                  title: string
                  text: string
                  references: string[] | undefined
                }) => ({
                  id: generateUUID(),
                  title: subsection.title,
                  text: subsection.text,
                  references: subsection.references,
                })
              ) ?? [],
          }))
          let filteredNewSections = newSections.filter(
            (section) => section.title && section.title !== ''
          )

          if (!filteredNewSections || filteredNewSections.length === 0) {
            throw new Error('An error occurred. Please try again.')
          }
          if (
            mode === GrantApplicationMode.DRAFTING &&
            filteredNewSections.length > 15
          ) {
            filteredNewSections = filteredNewSections
              .filter((section) => section.text && section.text !== '')
              .slice(0, 15)
          }

          setSteps({
            ...steps,
            [currentStep]: {
              ...steps[currentStep],
              numQuestions: 1,
            },
          })

          setSections({
            sections: filteredNewSections,
            history: [filteredNewSections],
            historyIndex: 0,
          })
        } catch (error) {
          console.error(error)
          if (numRetries === 0) {
            message.error('An error occurred. Please try again.')
            goBack()
          } else {
            setNumRetries((prevNumRetries) => prevNumRetries - 1)
            getAnswerWrapper(generateProposalDraftPrompt(), false, true)
            setSteps({
              ...steps,
              [currentStep]: {
                ...steps[currentStep],
                numQuestions: steps[currentStep].numQuestions + 1,
              },
            })
          }
        }
      }
    }
  }, [
    loading,
    questions[questions.length - 1]?.messages[1]?.message,
    steps[currentStep]?.sections,
  ])

  useEffect(() => {
    if (sections) {
      let refs: string[] = []
      for (const section of sections) {
        if (section.references) {
          refs = refs.concat(section.references)
        }
        for (const subsection of section.subsections ?? []) {
          if (subsection.references) {
            refs = refs.concat(subsection.references)
          }
        }
      }
      // remove duplicates
      refs = [...new Set(refs)]
      setReferences(refs)
    }
  }, [sections])

  const enhanceAllSections = async () => {
    if (
      sections &&
      sections.length > 0 &&
      !initialSectionsStatus &&
      !steps[currentStep]?.initialSectionsStatus &&
      finishedSteps === 0
    ) {
      setInitialSectionsStatus('enhancingTwice')
      setFinishedSteps((finished) => finished + 1)
      const enhanceSubsetOfSections = async (sections: Section[]) => {
        for (const section of sections) {
          // Enhance with data
          let enhancedSection = await enhance(
            setSections,
            setSteps,
            questions,
            selectedAgent.id,
            section,
            EnhanceType.LONGER,
            selectedConversation,
            user,
            sections
          )
          setFinishedSteps((finished) => finished + 1)

          // Enhance with citations
          enhancedSection = await enhance(
            setSections,
            setSteps,
            questions,
            selectedAgent.id,
            enhancedSection,
            EnhanceType.CITE,
            selectedConversation,
            user,
            sections
          )
          setFinishedSteps((finished) => finished + 1)
        }
      }
      // split sections into chunks of 4
      const chunkSize = 4
      const promises = []
      for (let i = 0; i < sections.length; i += chunkSize) {
        const sectionsChunk = sections.slice(i, i + chunkSize)
        // create a new promise for each chunk
        promises.push(enhanceSubsetOfSections(sectionsChunk))
      }
      // wait for all promises to resolve
      await Promise.all(promises)

      setSteps((steps: any) => ({
        ...steps,
        [currentStep]: {
          ...steps[currentStep],
          numQuestions: 1,
          initialSectionsStatus: 'enhancedTwice',
        },
      }))
      setInitialSectionsStatus('enhancedTwice')
    }
  }

  useEffect(() => {
    enhanceAllSections()

    if (mode == GrantApplicationMode.CONTINUE_EXISTING) {
      setInitialSectionsStatus('enhancedTwice')
      setSteps((steps: any) => ({
        ...steps,
        [currentStep]: {
          ...steps[currentStep],
          numQuestions: 1,
          initialSectionsStatus: 'enhancedTwice',
        },
      }))
    }

    if (initialSectionsStatus === 'enhancedTwice') {
      setSteps((steps: any) => ({
        ...steps,
        [currentStep]: {
          ...steps[currentStep],
          numQuestions: 1,
          initialSectionsStatus: 'enhancedTwice',
        },
      }))
    }
  }, [sections, initialSectionsStatus])

  const generateDocument = async () => {
    setGeneratingDocument(true)
    try {
      const res = await axios(`${API_URL}/google-doc/generate-grant`, {
        method: 'post',
        withCredentials: true,
        ...configHeader,
        data: JSON.stringify({
          sections: sections,
          user: user?.email,
        }),
      })

      setSteps({
        ...steps,
        [currentStep]: {
          ...steps[currentStep],
          documentUrl: res.data.url,
        },
      })
      setDocumentUrl(res.data.url)
    } catch (error) {
      console.error(error)
      message.error('Failed to generate the grant proposal document.')
      setGeneratingDocument(false)
    }
  }

  const collapseAll = () => {
    const newCollapsed = sections?.reduce(
      (acc, section) => ({ ...acc, [section.id]: true }),
      {}
    )
    setCollapsed(newCollapsed)
  }

  if (documentUrl) {
    return (
      <GoogleDocsViewer
        documentUrl={documentUrl}
        setDocumentUrl={setDocumentUrl}
        setGeneratingDocument={setGeneratingDocument}
        getAnswerWrapper={getAnswerWrapper}
      />
    )
  }

  const handleCountWords = () => {
    let numWords = 0
    for (const section of sections ?? []) {
      const sectionsWords = countWords(
        `${section.title} ${section.text} ${section.references?.join('\n')}`
      )
      const subsectionsWords = countWords(
        section.subsections?.reduce(
          (acc, subsection) =>
            `${acc} ${subsection.title} ${subsection.text} ${subsection.references?.join('\n')}`,
          ''
        ) ?? ''
      )
      numWords += sectionsWords + subsectionsWords
    }
    return numWords
  }

  return (
    <>
      {!generatingDocument &&
        sections &&
        !loading &&
        initialSectionsStatus === 'enhancedTwice' && (
          <div className='flex w-full items-center justify-between gap-2 bg-surface px-4 pb-3 text-on-surface transition-all dark:bg-dark-surface dark:text-dark-on-surface'>
            <div className='flex items-center gap-2'>
              <Button
                className='w-fit self-end'
                icon={<RiExpandVerticalLine className='size-4' />}
                onClick={() => setCollapsed({})}
              >
                <span className='!hidden lg:!flex'>Expand all sections</span>
              </Button>
              <Button
                className='w-fit self-end'
                icon={<RiCollapseVerticalLine className='size-4' />}
                onClick={collapseAll}
              >
                <span className='!hidden lg:!flex'>Collapse all sections</span>
              </Button>
              <AddSectionButton setEditing={setEditing} />
              <Tooltip title='Undo'>
                <Button
                  icon={
                    <RiArrowGoBackLine
                      className={cn(
                        'size-5',
                        isUndoDisabled ? 'opacity-40' : ''
                      )}
                    />
                  }
                  onClick={handleUndo}
                  className={cn(
                    'flex items-center',
                    isUndoDisabled
                      ? 'grayscale-[0.8] text-opacity-20 pointer-events-none'
                      : ''
                  )}
                />
              </Tooltip>
              <Tooltip title='Redo'>
                <Button
                  icon={
                    <RiArrowGoForwardLine
                      className={cn(
                        'size-5',
                        isRedoDisabled ? 'opacity-40' : ''
                      )}
                    />
                  }
                  onClick={handleRedo}
                  className={cn(
                    'flex items-center',
                    isRedoDisabled
                      ? 'grayscale-[0.8] text-opacity-20 pointer-events-none'
                      : ''
                  )}
                />
              </Tooltip>
            </div>
            <Tooltip title='Total number of words in the grant proposal draft.'>
              <div className='text-xs opacity-70'>
                {handleCountWords()} words
              </div>
            </Tooltip>
          </div>
        )}
      <div
        ref={answerRef}
        className={cn(
          'overflow-y-auto flex w-full p-2',
          !sections ||
            loading ||
            initialSectionsStatus !== 'enhancedTwice' ||
            generatingDocument
            ? 'h-[calc(100vh-62px)]'
            : 'h-[calc(100vh-106px)]'
        )}
      >
        <div
          className={cn(
            'relative m-auto min-h-full flex w-full flex-col gap-6 rounded-lg bg-surface p-6 text-left text-on-surface sm:max-w-[70em] dark:bg-dark-surface dark:text-dark-on-surface',
            !sections ||
              loading ||
              initialSectionsStatus !== 'enhancedTwice' ||
              generatingDocument
              ? 'h-auto'
              : ''
          )}
        >
          {!sections || loading || initialSectionsStatus !== 'enhancedTwice' ? (
            <>
              {mode === GrantApplicationMode.DRAFTING ? (
                <LoadingScreen
                  finishedSteps={finishedSteps}
                  allSteps={(sections?.length ?? 0) * 2 + 1}
                  lottieAnimation={lottieScanDocument}
                  text={[
                    'Reviewing grant requirements and guidelines...',
                    'Collecting relevant company data for the proposal...',
                    'Analyzing funding objectives to align your proposal...',
                    'Crafting a compelling executive summary...',
                    "Highlighting your company's key strengths and impact...",
                    'Formulating clear objectives and outcomes for the project...',
                    'Building a detailed budget and financial plan...',
                    'Clarifying project goals and anticipated outcomes...',
                    'Revising proposal sections for clarity and impact...',
                    'Ensuring all documentation meets grant specifications...',
                    'Compiling data on project sustainability and scalability...',
                    'Ensuring compliance with all grant submission criteria...',
                    'Finalizing the proposal with supporting documents...',
                    'Reviewing formatting for a professional presentation...',
                    'Conducting final checks for accuracy and completeness...',
                    'Preparing your polished grant proposal for review...',
                  ]}
                  timeInterval={20000}
                />
              ) : (
                <LoadingScreen
                  lottieAnimation={lottieScanDocument}
                  text={[
                    'Extracting content and preparing for editing...',
                    'Analyzing file structure and formatting...',
                    'Optimizing data for seamless editing...',
                    'Loading your document into the editor...',
                  ]}
                  timeInterval={20000}
                  infiniteLoader
                />
              )}
            </>
          ) : generatingDocument ? (
            <LoadingScreen
              lottieAnimation={lottieGeneratingDocument}
              text={[
                'Compiling all proposal sections into a single document...',
                'Formatting your grant proposal for clarity and professionalism...',
                'Incorporating supporting data and visuals...',
                'Finalizing the proposal document for review...',
                'Preparing your grant proposal for download...',
              ]}
              timeInterval={10000}
              infiniteLoader
            />
          ) : (
            <>
              <div className='m-auto flex w-full grow flex-col justify-between gap-4'>
                <SectionsDragDrop
                  enhancing={enhancing}
                  setEnhancing={setEnhancing}
                  editing={editing}
                  setEditing={setEditing}
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                />
                {references && references.length > 0 && (
                  <>
                    <div className='text-lg opacity-70'>References</div>
                    <ul className='markdown-answer list-disc pl-4'>
                      {references.map((reference, index) => (
                        <div className='break-words text-sm' key={index}>
                          <li>{markdown(reference)}</li>
                        </div>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <ProgressButtons
        containerRef={answerRef}
        goBack={goBack}
        goNext={generateDocument}
        disabledBack={generatingDocument}
        disabledNext={
          !sections ||
          loading ||
          initialSectionsStatus !== 'enhancedTwice' ||
          generatingDocument ||
          (enhancing &&
            Object.values(enhancing).some((isEnhancing) => isEnhancing))
        }
      />
    </>
  )
}

export default GrantEditor
