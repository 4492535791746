import { useContext } from 'react'

import { SidebarContext } from '@/context/sidebar/sidebar.context'

const useSidebarState = () => {
  const context = useContext(SidebarContext)
  if (!Object.keys(SidebarContext).length) {
    throw new Error('SidebarContext must be used within its provider')
  }
  return context
}

export default useSidebarState
