import { dateFormat, datetimeFormat } from '@/branding-config'

export const companyInfoPrompt = (
  companyUrl?: string,
  address?: string,
  numberOfEmployees?: string,
  annualRevenue?: string,
  additionalInfo?: string
) =>
  `<IMPORTANT>My company details:
Company URL: ${companyUrl}
${address ? `Company address: ${address}` : ''}
${numberOfEmployees ? `Number of employees: ${numberOfEmployees}` : ''}
${annualRevenue ? `Annual revenue: ${annualRevenue}` : ''}
${additionalInfo ? `Additional company information: ${additionalInfo}` : ''}
</IMPORTANT>

Research my company with the search and scrape function and context. Focus primarily on the provided Company URL.
Provide a detailed report with the information you learned about my company.
The report should be very long and very detailed.
The report should include the company's address, number of employees, annual revenue, and everything else about the company that you can find.
Ensure that your response is filled WITH FACTS, DATA, FIGURES, FINANCIALS, AND NUMBERS.
Include precise and detailed financial information about my company.
Include precise and detailed information about my company's projects, goals, objectives, and achievements.
FOR DATES ALWAYS USE AMERICAN DATE FORMAT: ${dateFormat}
FOR DATE TIME ALWAYS USE AMERICAN DATE TIME FORMAT: ${datetimeFormat}
YOU MUST RESPOND IN MARKDOWN FORMAT.`
